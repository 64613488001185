import { LossePlaatjie, useLosseLanguage } from '@ubo/losse-sjedel'
import ChevronRoundedRight from '~/components/elements/svg/ChevronRoundedRight'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  const [, locale] = useLosseLanguage()

  if (!data.title || !data.date) return null

  const publishedAt = new Date(data.date).toLocaleDateString(locale.replace('_', '-'), { day: '2-digit', month: 'long', year: 'numeric' })

  return (
    <a
      href={data.uri}
      className="group flex flex-col bg-white shadow-md hover:shadow-none hover:translate-y-1 border-2 border-gray-100 border-b-transparent hover:border-b-gray-100 rounded-2xl overflow-hidden p-4 lg:p-6 2xl:p-9 h-full transition-all"
    >
      <div className="flex flex-col sm:flex-row  justify-between">
        <div className="relative max-sm:-mx-2 -mt-2">
          <LossePlaatjie
            src={data.featuredImage?.node}
            className="object-cover aspect-video rounded-xl w-full sm:w-[130px] sm:h-[85px] group-hover:scale-125 transition-transform"
            maxwidth={600}
            title={data.title}
          />
        </div>
        <div className="flex justify-end flex-1">
          <div className="flex flex-col justify-between h-full">
            <small className="text-right max-sm:mt-2">{publishedAt}</small>
            <div className="hidden sm:block mt-auto">
              <ReadMore title={locale === 'nl_NL' ? 'Lees meer' : locale === 'es_ES' ? 'Leer más' : 'Read more'} />
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-base xs:text-lg sm:text-xl md:text-2xl font-semibold max-sm:mb-2 mt-4 sm:mt-8 text-site-base font-sans">
        {data.title}
      </h2>
      <div className="flex justify-end sm:hidden mt-auto lg:mt-2">
        <ReadMore title={locale === 'nl_NL' ? 'Lees meer' : locale === 'es_ES' ? 'Leer más' : 'Read more'} />
      </div>
    </a>
  )
}

function ReadMore({ title }: { title: string }) {
  return (
    <div className="flex items-center">
      <span className="inline-block mr-4 group-hover:underline text-sm 2xl:text-base">{title}</span>
      <ChevronRoundedRight />
    </div>
  )
}
