import { type LoaderData, useLosseLayout, useLosseLanguage } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()
  const [, locale] = useLosseLanguage()

  return (
    <nav
      data-component="Header"
      className={clsx(
        isSticky ? 'shadow h-[80px] bg-site-light' : 'h-[80px] lg:h-[140px] bg-transparent',
        'fixed left-0 top-0 right-0 z-[99] transition-all'
      )}
    >
      <div className="container flex h-full items-center justify-between">
        <LosseLink to={locale === 'nl_NL' ? '/' : locale === 'es_ES' ? '/es/' : '/en/'} aria-label="Home" className="z-50">
          <svg width="104" height="38" viewBox="0 0 104 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.4753 0L0 21.5684H5.37876V21.5472H5.82967C7.77289 20.5581 11.1118 19.2499 15.1593 19.2499C19.2068 19.2499 22.653 20.5474 24.7573 21.5472H24.9291V21.5684H30.3186L17.854 0H12.4753ZM19.9368 12.9432L21.5794 15.7828C19.6684 15.283 17.4997 14.9214 15.17 14.9214C12.8403 14.9214 10.5965 15.2936 8.70693 15.8147L10.3817 12.9326H10.371L10.7468 12.3157L15.1593 4.03079L19.604 12.3157L19.9476 12.9326L19.9368 12.9432Z"
              fill="#44277C"
            />
            <path d="M36.5455 0H31.886V21.5684H54.5605V17.2611H36.5455V0Z" fill="#44277C" />
            <path d="M76.9559 0H51.7584V4.31794H62.0328V21.5684H66.6923V4.31794H76.9559V0Z" fill="#44277C" />
            <path
              d="M79.0494 21.5684V21.5472H79.2749C81.2181 20.5581 84.557 19.2499 88.6045 19.2499C92.652 19.2499 96.0983 20.5474 98.2025 21.5472H98.5998V21.5684H103.989L91.5247 0H86.146L73.6707 21.5684H79.0494ZM84.396 12.3263L88.8085 4.04142L93.2532 12.3263L93.5968 12.9432L95.2716 15.8573C93.2962 15.3149 91.0309 14.932 88.5938 14.932C86.3177 14.932 84.2242 15.2723 82.3991 15.7509L84.031 12.9432H84.0202L84.396 12.3263Z"
              fill="#44277C"
            />
            <path d="M75.9682 27.1626H73.6707V37.7767H75.9682V27.1626Z" fill="#44277C" />
            <path
              d="M85.3944 29.0557C87.1874 29.0557 88.7763 29.3854 90.3223 29.8321L90.8376 27.7901C90.1505 27.5668 88.2395 26.9393 85.3944 26.9393C79.8224 26.9393 77.4927 29.3216 77.4927 32.4696C77.4927 35.6177 79.8224 38 85.3944 38C88.2395 38 90.1505 37.3832 90.8376 37.1492L90.3223 35.1072C88.7763 35.5432 87.1874 35.8836 85.3944 35.8836C81.3577 35.8836 79.8439 34.4691 79.8439 32.4696C79.8439 30.4702 81.3577 29.0557 85.3944 29.0557Z"
              fill="#44277C"
            />
            <path d="M91.6106 29.2897H96.6565V37.7767H98.9541V29.2897H104V27.1626H91.6106V29.2897Z" fill="#44277C" />
          </svg>
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
    </nav>
  )
}
