import { useLosseLanguage, useSearchParams } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { READABLE_PARAMS } from './PostsBlog'

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [, lang] = useLosseLanguage()
  const [search, setSearch] = useState(searchParams.get(READABLE_PARAMS.query) || '')

  return (
    <div className="flex flex-col md:flex-row items-center">
      <label htmlFor="input-search" className="font-semibold text-white h-fit">
        {lang === 'nl_NL' ? 'Zoek in alle blogs' : lang === 'es_ES' ? 'Buscar en todos los blogs' : 'Search in all blogs'}
      </label>
      <div className="flex justify-end items-center md:ml-4">
        <div className="flex-1 w-full">
          <input
            type="text"
            name={READABLE_PARAMS.query}
            placeholder={lang === 'nl_NL' ? 'Typ hier om te zoeken' : lang === 'es_ES' ? 'Escribe aquí para buscar' : 'Type here to search'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault()
                searchParams.set(READABLE_PARAMS.query, search)
                setSearchParams(searchParams, {
                  replace: true,
                  state: {
                    scroll: false
                  }
                })
              }
            }}
            className="bg-white text-site-base placeholder:text-[#91B0C5] rounded-full pl-5 pr-12 py-2 xl:py-3 lg:min-w-[276px]"
          />
        </div>
        <div className="-ml-12 h-full flex items-center">
          <button
            type="button"
            className="btn btn-site-base py-[10px] xl:py-[14px] min-w-[unset] text-sm uppercase hover:cursor-pointer hover:underline font-semibold"
            onClick={() => {
              searchParams.set(READABLE_PARAMS.query, search)
              setSearchParams(searchParams, {
                replace: true,
                state: {
                  scroll: false
                }
              })
            }}
          >
            {lang === 'nl_NL' ? 'Zoeken' : lang === 'es_ES' ? 'Buscar' : 'Search'}
          </button>
        </div>
      </div>
    </div>
  )
}
