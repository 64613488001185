import { useEffect, useState } from 'react'
import { LosseLink, useLoaderData, useLocation, useLosseLanguage } from '@ubo/losse-sjedel'
import { AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import Button from '~/components/elements/Button'
import type { Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import clsx from 'clsx'
import LanguageSwitch from '~/components/elements/LanguageSwitch'

export default function HeaderMenu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [translations, currentLocale] = useLosseLanguage()
  const location = useLocation()

  function handleSubmenu(open: boolean, index: number) {
    if (open) {
      setCurrentIndex(-1)
    } else {
      setCurrentIndex(index)
    }
  }

  useEffect(() => {
    setCurrentIndex(-1)
  }, [location.pathname])

  let translationUrl = translations?.[0]?.href || '/'

  if (translationUrl === '/en/alta-ict-always-the-best-of-microsoft-365/') {
    translationUrl = '/en/'
  } else if (translationUrl === '/alta-ict-altijd-het-beste-van-microsoft-365/') {
    translationUrl = '/'
  } else if (translationUrl === '/es/alta-ict-siempre-lo-mejor-de-microsoft-365/') {
    translationUrl = '/es/'
  }

  return (
    <>
      <ul className="w-full items-center lg:justify-end flex flex-col lg:flex-row lg:mr-4 xl:mr-12">
        {header?.menuHeader?.map((edge, index) => {
          const submenuOpen = currentIndex === index

          const cleanUrl = edge.link.url.replace(/[\\/]/g, '')
          const parentPageUrl = location.pathname.split('/')[1]

          const partiallyCurrent = cleanUrl === parentPageUrl

          return (
            <li
              className="lg:mx-3 xl:mx-5 text-3xl lg:text-base max-lg:py-1"
              key={index}
              onMouseEnter={() => setCurrentIndex(index)}
              onMouseLeave={() => setCurrentIndex(-1)}
              onClick={(e) => {
                e.stopPropagation()

                setCurrentIndex(index)
              }}
            >
              <div className="group flex flex-wrap justify-center items-center">
                <LosseLink className="group relative font-semibold text-site-base px-2" to={edge?.link?.url || '/'}>
                  <div
                    className={clsx(
                      partiallyCurrent || submenuOpen
                        ? 'translate-y-[1px] opacity-100'
                        : 'translate-y-1 group-hover:translate-y-[1px] opacity-0 group-hover:opacity-100',
                      'absolute bottom-0 left-0 w-full bg-site-base-50 h-3 rounded-full transition-transform'
                    )}
                  ></div>
                  <span className="relative">{edge?.link?.title}</span>
                </LosseLink>
                {edge?.submenu && (
                  <div
                    onClick={() => handleSubmenu(submenuOpen, index)}
                    className={clsx(currentIndex === index ? 'rotate-90' : 'rotate-0', 'ml-1 mt-1 transition-transform')}
                    aria-label={`Submenu ${edge?.link?.title}`}
                  >
                    <svg
                      className="max-lg:w-7 max-lg:h-5"
                      width="6"
                      height="12"
                      viewBox="0 0 6 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.10833 11.0302L0.0500793 9.96973L4.01682 5.99998L0.0500793 2.03023L1.11133 0.969727L5.07507 4.93948C5.35628 5.22077 5.51425 5.60223 5.51425 5.99998C5.51425 6.39772 5.35628 6.77918 5.07507 7.06048L1.10833 11.0302Z"
                        fill="#44277C"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {edge?.submenu && submenuOpen && (
                <div className="w-screen flex justify-center absolute left-0">
                  <div className="container">
                    <AnimatePresence>
                      <HeaderSubMenu fields={edge.submenu as Component_Header_MenuHeader_Submenu[]} />
                    </AnimatePresence>
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>

      <ul className="flex flex-col lg:flex-row items-center">
        <li className="lg:order-last max-lg:pb-1 max-lg:pt-2">
          <Button to={header.ctaHeader.url} className="btn-default">
            {header.ctaHeader.title}
          </Button>
        </li>
        <LanguageSwitch className="lg:mr-9 lg:order-first max-lg:py-2" />
      </ul>
    </>
  )
}
